import React, { useState, useEffect } from 'react';

import {
    cleanMealEditService,
    initMealEditService,
    removeMealEditService,
    saveMealChanges,
    unRegisterItemsChangingObserver,
} from "../../services/mealEditService";
import Loading from "../Loading";
import {RepeatIcon} from "../../appearence/icons/repeat_icon";
import {PenIcon} from "../../appearence/icons/pen_icon";
import {i18n} from "../../i18n";
import MealPlanEditableIngredientsList from "./MealPlanEditableIngredientsList";
import MealPlanItemIngredientsList from "./MealPlanItemIngredientsList";
import {
    sendWSMessage,
    WEBSOCKET_EVENT_TYPE_MEAL,
} from '../sharedComponents/WebSocketConnectionService';
import {getTimeFromData} from "./util";
import {RECIPE_CHARACTERISTIC_SOURCE, RECIPE_CHARACTERISTIC_SOURCE_CHAPCHE, RECIPE_CHARACTERISTIC_SOURCE_VKUSVILL} from "../../constant";
import {VkusVillSourceIcon} from "../../appearence/icons/vkusvill_source_icon";

const getSourceIcon = (mealItem) => {
    const source = mealItem.characteristics &&
        mealItem.characteristics.length > 0 &&
        mealItem.characteristics.find(it => it.category === RECIPE_CHARACTERISTIC_SOURCE && it.value !== RECIPE_CHARACTERISTIC_SOURCE_CHAPCHE);
    if(source && source.value === RECIPE_CHARACTERISTIC_SOURCE_VKUSVILL) {
        return <VkusVillSourceIcon nameOfClass={`icon plan__item-icon`}/>;
    }
    return null;
};

const canBeEditable = (mealItem) => {
    if(mealItem.characteristics &&
        mealItem.characteristics.length > 0 &&
        mealItem.characteristics.find(it => it.category === RECIPE_CHARACTERISTIC_SOURCE && it.value !== RECIPE_CHARACTERISTIC_SOURCE_CHAPCHE)) {
        return false;
    }
    return true;
};

const buildMealName = (mealItem) => {
    if(mealItem.names && mealItem.names.length > 0 && mealItem.names.find(it => it.locale === i18n.APP_LOCALE)) {
        return mealItem.names.find(it => it.locale === i18n.APP_LOCALE).name;
    }
    return i18n.get(`meal.scheme.mealType.${mealItem.mealType}`);
};

const MealItemLayout = ({mealItem}) => {
    const [loading, setLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isIngredientsChangingDisabled, setIsIngredientsChangingDisabled] = useState(true);
    useEffect(() => {
        initMealEditService(mealItem.id).registerItemsChangingObserver(processItemsChanging);
        return () => {
            unRegisterItemsChangingObserver(mealItem.id, processItemsChanging);
            removeMealEditService(mealItem.id);
        };
    }, [mealItem.id]);
    const processItemsChanging = () => {
        setIsIngredientsChangingDisabled(false);
    };
    const handleRecreatePlanItem = (e) => {
        if(e) {
            e.stopPropagation();
        }
        setLoading(true);
        sendWSMessage(WEBSOCKET_EVENT_TYPE_MEAL, {
            startDate: mealItem.mealTime,
            endDate: mealItem.mealTime,
        });
    };
    const handleShowIngredients = () => {
        if (isEditable) {
            cleanMealEditService(mealItem.id);
        }
        setIsEditable(!isEditable);
    };
    const handleHideIngredients = () => {
        cleanMealEditService(mealItem.id);
        setIsEditable(false);
    };
    const saveIngredientsChanges = () => {
        setIsIngredientsChangingDisabled(true);
        setIsEditable(false);
        setLoading(true);
        saveMealChanges(mealItem.id, handleRecreatePlanItem);
    };
    return (
        <li key={mealItem.id} className="plan__item">
            {loading ?
                <Loading /> :
                <>
                    <div className="plan__item-header">
                        <h3 className="main__subtitle plan__time">{getTimeFromData(mealItem.mealTime)}</h3>
                        <span onClick={handleRecreatePlanItem}>
                            <RepeatIcon nameOfClass="icon plan__item-icon"/>
                        </span>
                        {canBeEditable(mealItem) ?
                            <PenIcon nameOfClass={`icon ${isEditable ? 'icon-active' : ''} plan__item-icon`} handleClick={handleShowIngredients}/> :
                            getSourceIcon(mealItem)}
                    </div>
                    <h4 className="main__subtitle plan__name">{buildMealName(mealItem)}</h4>
                    {isEditable ?
                        <MealPlanEditableIngredientsList mealId={mealItem.id} ingredients={mealItem.ingredients} /> :
                        <MealPlanItemIngredientsList ingredients={mealItem.ingredients} />}
                    {isEditable ?
                        <div className="button__group">
                            <button className="block__button button__group-item button button_outline" onClick={handleHideIngredients}>{i18n.get(`app.page.product.cancel`)}</button>
                            <button className={`block__button button__group-item button ${isIngredientsChangingDisabled ? 'button_disabled' : ''}`} onClick={saveIngredientsChanges}>
                                {i18n.get(`app.page.product.saveChangesSmall`)}
                            </button>
                        </div> :
                        null}
                </>
            }
        </li>
    );
};

export default MealItemLayout;
