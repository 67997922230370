import React from "react";

export const VkusVillSourceIcon = ({nameOfClass}) => {
    return <svg className={nameOfClass} style={{fill:"#2DBE64"}} viewBox="0 0 1417.32 1417.32" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M1051.6,947.11H708.86V463.18h312.12c100.74,0,155.43,62.78,155.43,137.08c0,33.34-16.8,67.42-42.58,93.2		c37.99,20.06,70.56,65.26,70.56,112.39C1204.39,888.75,1139.67,947.11,1051.6,947.11z M828.48,849.76h206.84		c28.98,0,48.06-21.43,48.06-47.78v-0.77c0-26.34-19.08-47.78-48.06-47.78H828.48V849.76z M828.48,656.08h176.95		c31.32,0,49.97-21.43,49.97-47.78c0-26.34-17.49-47.78-49.97-47.78H828.48V656.08z"/>
            <path d="M498.53,947.11H245.27V463.18h222.64c100.74,0,155.43,62.78,155.43,137.08c0,33.34-16.8,67.42-42.58,93.2		c37.99,20.06,70.56,65.26,70.56,112.39C651.32,888.75,586.6,947.11,498.53,947.11z M364.88,849.76h117.37		c28.98,0,48.06-21.43,48.06-47.78v-0.77c0-26.34-19.08-47.78-48.06-47.78H364.88V849.76z M364.88,656.08h87.48		c31.32,0,49.97-21.43,49.97-47.78c0-26.34-17.49-47.78-49.97-47.78h-87.48V656.08z"/>
        </g>
    </svg>;
};
